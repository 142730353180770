export const en = {
  home: {
    wellcome: "Wellcome to my page!",
    hello: "Hello Visitor!",
    devInfo: "Fullstack Javascript Software Engineer",
    myName: "I’m Mago.",
    introduction: "Introduction",
    introductionName: "My name is João Pedro, known as Mago.",
    introductionInfo:
      "I’m a Brazillian Fullstack developer by profession, solo indie game developer by passion. My world revolves around crafting digital experiences.",
  },
};
