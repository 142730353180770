import styled from "styled-components";

export const Container = styled.div`
  margin: 2vw;
  text-align: left;

  @media (max-width: ${(props) => props.theme.size.laptop}) {
    margin: 0;
  }
`;

export const Title = styled.p`
  color: ${(props) => props.theme.color.darkBg};
  font-size: 2rem;
  font-weight: bold;

  @media (max-width: ${(props) => props.theme.size.laptop}) {
    font-size: 32px;
  }
`;

export const Text = styled.p`
  color: ${(props) => props.theme.color.darkBg};
  font-size: 1.3vw;

  @media (max-width: ${(props) => props.theme.size.laptop}) {
    font-size: 21px;
  }
`;

export const Image = styled.img`
  float: left;
  margin-right: 0.5vw;
  border-radius: 50%;
  width: 8vw;

  @media (max-width: ${(props) => props.theme.size.laptop}) {
    width: 150px;
    margin: 0 auto 4vw;
    float: none;
    display: block;
  }
`;

export const Flag = styled.img`
  margin: 0;
  padding: 0;
`;

export const FlagButton = styled.button`
  float: right;

  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  height: fit-content;
  width: fit-content;
`;
