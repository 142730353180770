import { useEffect, useState } from "react";
import { getProfileInfo } from "services";
import { GitHubProfile } from "shared/interfaces";
import * as S from "./style";
import { Wellcome, Code, SocialMedia, Introduction } from "./components";

const Home = () => {
  const [userInfo, setUserInfo] = useState<Partial<GitHubProfile>>({});

  const setUser = async () => {
    const gitHubUser = await getProfileInfo();
    if (gitHubUser) {
      setUserInfo(gitHubUser);
    }
  };

  useEffect(() => {
    setUser();
  }, []);

  return (
    <S.Container>
      <S.LeftContent>
        <Wellcome />
        <SocialMedia />
      </S.LeftContent>
      <S.RightContent>
        <S.HalfDivContent>
          <Code />
        </S.HalfDivContent>
        <S.HalfDivContent $color="lightBg">
          <Introduction userInfo={userInfo} />
        </S.HalfDivContent>
      </S.RightContent>
    </S.Container>
  );
};

export default Home;
