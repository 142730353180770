import { theme } from "shared/assets/theme";
import { ThemeProvider } from "styled-components";
import Routing from "./routes";
import "./locale/i18n";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Routing />
    </ThemeProvider>
  );
};

export default App;
