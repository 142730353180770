import styled from "styled-components";
import SyntaxHighlighter from "react-syntax-highlighter";

export const App = styled.div`
  text-align: left;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const CodeFrame = styled(SyntaxHighlighter)`
  margin: 0 5rem;
  border-radius: 2%;
  font-size: 1vw;

  @media (max-width: ${(props) => props.theme.size.laptop}) {
    font-size: 21px;
    margin: 0;
  }
`;
