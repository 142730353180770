export const theme = {
  color: {
    darkBg: "#202124",
    grayBg: "#4f535f",
    lightBg: "#9B9EA7",
  },
  size: {
    laptop: "1366px",
  },
};

export type themeType = "darkBg" | "grayBg" | "lightBg";
