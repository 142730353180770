import styled from "styled-components";

export const Container = styled.div`
  text-align: center;
`;
export const SocialLink = styled.a`
  color: ${(props) => props.theme.color.grayBg};
  text-decoration: none;
  font-size: 8vw;
  margin: 1vw;

  :hover {
    color: ${(props) => props.theme.color.lightBg};
  }

  @media (max-width: ${(props) => props.theme.size.laptop}) {
    font-size: 64px;
  }
`;
