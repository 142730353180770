import { useTranslation } from "react-i18next";
import * as S from "./style";

const Home = () => {
  const { t } = useTranslation("home");

  return (
    <S.Container>
      <S.Text>{t("hello")}</S.Text>
      <S.BigText>{t("wellcome")}</S.BigText>
      <S.Text>{t("myName")}</S.Text>
      <S.Text>{t("devInfo")}</S.Text>
    </S.Container>
  );
};

export default Home;
