import { GitHubProfile } from "shared/interfaces";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

import * as S from "./style";
import brazil from "../../../../shared/assets/images/brazil.png";

interface IntroductionProps {
  userInfo: Partial<GitHubProfile>;
}

const Introduction = ({ userInfo }: IntroductionProps) => {
  const { t } = useTranslation("home");

  const changeLanguage = () => {
    if (i18n.language === "ptBr") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("ptBr");
    }
  };

  return (
    <S.Container>
      <S.Title>{t("introduction")}</S.Title>
      <S.Text>
        {userInfo.avatar_url && (
          <S.Image src={userInfo.avatar_url} alt="Profile" />
        )}
        {t("introductionName")}
        <br />
        {t("introductionInfo")}
      </S.Text>
      <S.Text>
        Email: zaconou@gmail.com{" "}
        <S.FlagButton onClick={changeLanguage}>
          <S.Flag src={brazil} alt="Profile" />
        </S.FlagButton>
      </S.Text>
    </S.Container>
  );
};

export default Introduction;
