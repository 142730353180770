import axios from "axios";
import { GitHubProfile } from "shared/interfaces";

export const getProfileInfo = async (): Promise<GitHubProfile | undefined> => {
  try {
    const githubInfo = await axios.get<GitHubProfile>(
      "https://api.github.com/users/joaopedromago"
    );
    return githubInfo.data;
  } catch {
    return undefined;
  }
};
