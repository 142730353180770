import styled from "styled-components";

export const Container = styled.div`
  margin: 5vw;
  text-align: left;
  color: white;

  @media (max-width: ${(props) => props.theme.size.laptop}) {
    margin: 5vw;
  }
`;

export const Text = styled.p`
  font-size: 5vh;
  line-height: 5vh;
  margin: 0;

  @media (max-width: ${(props) => props.theme.size.laptop}) {
    font-size: 48px;
    line-height: 48px;
  }
`;

export const BigText = styled.p`
  font-size: 15vh;
  line-height: 15vh;
  padding-bottom: 5vh;
  margin: 0;

  @media (max-width: ${(props) => props.theme.size.laptop}) {
    font-size: 20vw;
    line-height: 20vw;
  }
`;
