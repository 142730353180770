import { Home } from "pages";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { routes } from "./routes";

export const Routing = () => (
  <Router>
    <Routes>
      <Route path={routes.HOME} element={<Home />} />
    </Routes>
  </Router>
);

export default Routing;
