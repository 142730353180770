import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
  faGithubSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import * as S from "./style";

const Home = () => {
  return (
    <S.Container>
      <S.SocialLink
        href="https://www.linkedin.com/in/zaconou"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faLinkedin} />
      </S.SocialLink>
      <S.SocialLink
        href="https://github.com/joaopedromago"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faGithubSquare} />
      </S.SocialLink>
      <S.SocialLink
        href="https://www.instagram.com/jaomago/"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faInstagram} />
      </S.SocialLink>
      <S.SocialLink
        href="https://www.facebook.com/mahjao"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faFacebookSquare} />
      </S.SocialLink>
    </S.Container>
  );
};

export default Home;
