import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

import * as S from "./style";

const Home = () => {
  const codeString = `const Profile = () => (
  <About>
    <ILike>Games 🕹</ILike>
    <ILike>Music 🎸</ILike>
    <ILike>Cats 🐈</ILike>
    <ILike>Coding ⌨️</ILike>
  </About>
);

export default Profile;`;
  return (
    <S.App>
      <S.CodeFrame language="javascript" style={a11yDark}>
        {codeString}
      </S.CodeFrame>
    </S.App>
  );
};

export default Home;
