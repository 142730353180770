export const ptBr = {
  home: {
    wellcome: "Bem-vindo à minha página!",
    hello: "Olá visitante!",
    devInfo: "Engenheiro de Software Javascript Fullstack",
    myName: "Eu sou o Mago.",
    introduction: "Introdução",
    introductionName: "Meu nome é João Pedro, conhecido como Mago.",
    introductionInfo:
      "Desenvolvedor Fullstack por profissão, desenvolvedor de jogos indie solo por paixão. Meu mundo gira em torno da criação de experiências digitais.",
  },
};
