import { themeType } from "shared/assets/theme";
import styled from "styled-components";

export const Container = styled.div`
  background-color: ${(props) => props.theme.color.darkBg};
  min-height: 100vh;
  display: flex;

  @media (max-width: ${(props) => props.theme.size.laptop}) {
    display: block;
  }
`;

export const LeftContent = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.size.laptop}) {
    width: 100%;
    min-height: 87.5vh;
  }
`;

export const RightContent = styled.div`
  width: 35%;
  background-color: ${(props) => props.theme.color.grayBg};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.size.laptop}) {
    flex-direction: column-reverse;
    width: 100%;
    padding-top: 5vh;
  }
`;

export const HalfDivContent = styled.div<{
  $color?: themeType;
}>`
  height: 50vh;
  background-color: ${(props) =>
    (props.$color && props.theme.color[props.$color]) || "transparent"};

  @media (max-width: ${(props) => props.theme.size.laptop}) {
    height: auto;
    padding: 5vh;
    width: 80%;
    margin: auto;
  }
`;
